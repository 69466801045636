/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

/** this file is used by project to add new icons */
import dynamicHydrate from '@bluheadless/ui-logic/src/dynamic-hydration/dynamic-hydrate'
import EmptyIcon from '../icon/empty'

export * from './project-related-icons'
export const Bag2FilledHeaderIcon = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/bag-2-filled-header-icon'),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Bag2FilledHeaderIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/bag-2-filled-header-icon').then((mod) => mod.Bag2FilledHeaderIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const CustomercareIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/customercare-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const CustomercareIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/customercare-icon').then((mod) => mod.CustomercareIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Customize = dynamicHydrate(() => import('@/components/ui/atoms/icons/customize'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const CustomizeSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/customize').then((mod) => mod.CustomizeSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const HeartFilledHeaderIcon = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/heart-filled-header-icon'),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const HeartFilledHeaderIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/heart-filled-header-icon').then((mod) => mod.HeartFilledHeaderIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3080Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3080-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3080IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3080-icon').then((mod) => mod.Iso3080IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3081Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3081-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3081IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3081-icon').then((mod) => mod.Iso3081IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3085Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3085-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3085IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3085-icon').then((mod) => mod.Iso3085IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3086Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3086-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3086IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3086-icon').then((mod) => mod.Iso3086IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3087Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3087-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3087IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3087-icon').then((mod) => mod.Iso3087IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3088Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3088-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3088IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3088-icon').then((mod) => mod.Iso3088IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3089Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3089-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3089IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3089-icon').then((mod) => mod.Iso3089IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3090Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3090-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3090IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3090-icon').then((mod) => mod.Iso3090IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3091Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3091-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3091IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3091-icon').then((mod) => mod.Iso3091IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3092Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3092-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3092IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3092-icon').then((mod) => mod.Iso3092IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3093Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3093-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3093IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3093-icon').then((mod) => mod.Iso3093IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3094Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3094-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3094IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3094-icon').then((mod) => mod.Iso3094IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3095Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3095-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3095IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3095-icon').then((mod) => mod.Iso3095IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3096Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3096-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3096IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3096-icon').then((mod) => mod.Iso3096IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3097Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3097-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3097IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3097-icon').then((mod) => mod.Iso3097IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3098Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3098-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3098IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3098-icon').then((mod) => mod.Iso3098IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3099Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3099-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3099IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3099-icon').then((mod) => mod.Iso3099IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3100AIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3100-a-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3100AIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3100-a-icon').then((mod) => mod.Iso3100AIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3100BIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3100-b-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3100BIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3100-b-icon').then((mod) => mod.Iso3100BIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3101Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3101-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3101IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3101-icon').then((mod) => mod.Iso3101IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3102Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3102-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3102IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3102-icon').then((mod) => mod.Iso3102IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3103AIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3103-a-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3103AIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3103-a-icon').then((mod) => mod.Iso3103AIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3103BIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3103-b-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3103BIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3103-b-icon').then((mod) => mod.Iso3103BIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3104AIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3104-a-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3104AIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3104-a-icon').then((mod) => mod.Iso3104AIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3104BIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3104-b-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3104BIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3104-b-icon').then((mod) => mod.Iso3104BIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3105AIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3105-a-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3105AIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3105-a-icon').then((mod) => mod.Iso3105AIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3105BIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3105-b-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3105BIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3105-b-icon').then((mod) => mod.Iso3105BIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3106AIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3106-a-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3106AIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3106-a-icon').then((mod) => mod.Iso3106AIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3106BIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3106-b-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3106BIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3106-b-icon').then((mod) => mod.Iso3106BIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3107Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3107-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3107IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3107-icon').then((mod) => mod.Iso3107IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3108Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3108-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3108IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3108-icon').then((mod) => mod.Iso3108IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3109Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3109-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3109IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3109-icon').then((mod) => mod.Iso3109IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3110Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3110-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3110IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3110-icon').then((mod) => mod.Iso3110IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3111Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3111-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3111IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3111-icon').then((mod) => mod.Iso3111IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3112Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3112-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3112IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3112-icon').then((mod) => mod.Iso3112IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3113Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3113-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3113IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3113-icon').then((mod) => mod.Iso3113IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3114Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3114-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3114IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3114-icon').then((mod) => mod.Iso3114IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3115Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3115-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3115IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3115-icon').then((mod) => mod.Iso3115IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3116Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3116-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3116IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3116-icon').then((mod) => mod.Iso3116IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3117Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3117-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3117IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3117-icon').then((mod) => mod.Iso3117IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3118Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3118-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3118IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3118-icon').then((mod) => mod.Iso3118IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3119Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3119-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3119IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3119-icon').then((mod) => mod.Iso3119IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3120Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3120-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3120IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3120-icon').then((mod) => mod.Iso3120IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3121Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3121-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3121IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3121-icon').then((mod) => mod.Iso3121IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3122Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3122-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3122IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3122-icon').then((mod) => mod.Iso3122IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3123Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3123-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3123IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3123-icon').then((mod) => mod.Iso3123IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3124Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3124-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3124IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3124-icon').then((mod) => mod.Iso3124IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Iso3125Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-3125-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Iso3125IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-3125-icon').then((mod) => mod.Iso3125IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const IsoLa14Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-la-14-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const IsoLa14IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-la-14-icon').then((mod) => mod.IsoLa14IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const IsoLs05Icon = dynamicHydrate(() => import('@/components/ui/atoms/icons/iso-ls-05-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const IsoLs05IconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/iso-ls-05-icon').then((mod) => mod.IsoLs05IconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const WorldIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/world-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const WorldIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/world-icon').then((mod) => mod.WorldIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
